import type { Primitive } from "@/types/primitive";

export function encodeObjectToFormUrl(obj: Record<string, Primitive>) {
  return Object.entries(obj).reduce(
    (formUrl, [key, value], index) =>
      formUrl +
      `${index === 0 ? "" : "&"}${key}=${encodeURIComponent(value ?? "")}`,
    ""
  );
}
