import { encodeObjectToFormUrl } from "@/utils/url";
import { cn } from "@reftree/ui";
import { useState } from "react";
interface HomeGetNotifiedProps {
  id: string;
}

type FormStatus = "idle" | "success" | "error" | "submitting";

export default function HomeGetNotified({ id }: HomeGetNotifiedProps) {
  const [status, setStatus] = useState<FormStatus>("idle");

  const [message, setMessage] = useState<string | null>(null);

  const handleNewsletterSubmit: React.FormEventHandler<
    HTMLFormElement
  > = async (e) => {
    e.preventDefault();
    const data = new FormData(e.target as HTMLFormElement);

    const email = data.get("email") as string;
    const userGroup = data.get("userGroup") as string;

    setStatus("submitting");

    try {
      const result = await fetch(
        "https://app.loops.so/api/newsletter-form/clvw295is02njy7f8t95tkazh",
        {
          method: "POST",
          body: encodeObjectToFormUrl({ email, userGroup }),
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      ).then((res) => res.json());

      if (!result.success) {
        setStatus("error");
        setMessage(result.message);
        return;
      }

      setStatus("success");
      setMessage("You have successfully signed up! Thank you very much!");
    } catch (e) {
      setStatus("error");
      setMessage("An unknown error ocurred");
    }
  };

  return (
    <section
      id={id}
      className="w-full flex item-center justify-center py-12 md:py-24 lg:py-32"
    >
      <div className="w-full relative flex flex-col items-center justify-center antialiased">
        <div className="max-w-2xl relative z-10 mx-auto p-4">
          <div className="space-y-8">
            <h1 className="relative z-10 text-3xl md:text-5xl text-center text-neutral-800 font-sans font-bold">
              Get notified when it{" "}
              <span className="text-primary">launches</span>
            </h1>
            <p></p>
            <p className="text-neutral-600 max-w-xl mx-auto my-2 text-lg text-center relative z-10">
              Sign up to our pre-launch newsletter and join us on our journey to
              make referral marketing easy for everyone!
            </p>
            <form
              onSubmit={handleNewsletterSubmit}
              className="flex flex-col lg:flex-row gap-4 max-w-sm mx-auto lg:max-w-none bg-white "
            >
              <div className="w-full relative">
                <div className="absolute left-3 top-0 translate-y-1/2">
                  <svg
                    width="21"
                    height="20"
                    viewBox="0 0 21 20"
                    fill="none"
                    stroke="#1D4ED8"
                    xmlns="http://www.w3.org/2000/svg"
                    className="stroke-green-500"
                  >
                    <g clipPath="url(#clip0_4036_225)">
                      <path
                        d="M13 8.3335L9.66667 11.6668L14.6667 16.6668L18 3.3335L3 9.16683L6.33333 10.8335L8 15.8335L10.5 12.5002"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      ></path>
                    </g>
                    <defs>
                      <clipPath id="clip0_4036_225">
                        <rect
                          width="20"
                          height="20"
                          fill="white"
                          transform="translate(0.5)"
                        ></rect>
                      </clipPath>
                    </defs>
                  </svg>
                </div>
                <input
                  disabled={status === "submitting" || status === "success"}
                  name="email"
                  placeholder="Fill in your email to get started"
                  required
                  type="email"
                  className="flex-1 pl-10 w-full border border-gray-200 rounded-md focus:ring-green-500 px-4 py-2 disabled:bg-muted"
                />
                <p
                  className={cn(
                    "pt-2 pl-4",
                    message ? "opacity-100" : "opacity-0",
                    status === "success" ? "text-primary" : "text-destructive"
                  )}
                >
                  {message}
                </p>
                <input
                  type="hidden"
                  name="userGroup"
                  value="prelaunchNL"
                ></input>
              </div>
              <button
                className="flex-shrink-0 border font-semibold text-white font-inter px-4 py-2 rounded-md border-primary bg-primary transition-colors duration-300 h-fit disabled:bg-muted disabled:border-muted disabled:text-accent-foreground"
                type="submit"
                disabled={status === "submitting" || status === "success"}
              >
                {status === "submitting" ? "Loading" : "Notify me"}
              </button>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
}
